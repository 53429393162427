import isBlank from 'is-blank'

export default {
    isBlank(val) {
        return isBlank(val)
    },
    createErrorFromList(errors) {
        let message = {
            type: 'danger',
            show: true,
            html: 'Network Error.',
            icon: 'fa-warning',
            timeout: 2500,
        }

        message.html = errors.map(item => {
            var el = document.createElement("li");
            el.innerText = el.textContent = item;
            return el.innerHTML;
        })

        message.html = '<ul>Terdapat kesalahan berikut:' + message.html + '</ul>'
        return message
    },
    createError(error) {
        //Quasar Toast Schema
        let message = {
            type: 'danger',
            show: true,
            html: 'Network Error.',
            icon: 'fa-warning',
            timeout: 2500,
        }

        if (typeof error !== 'undefined' && error != null && error.hasOwnProperty('message')) {
            message.html = error.message
        }

        if (typeof error.response !== 'undefined' && error.response != null) {
            //Setup Generic Response Messages
            message.httpStatus = error.response.status
            if (error.response.status === 401) {
                message.html = 'UnAuthorized'
                //vm.$emit('logout') //Emit Logout Event
            } else if (error.response.status === 403) {
                message.html = 'Not authorized to access resource'
            } else if (error.response.status === 404) {
                message.html = 'API Route is Missing or Undefined'
            } else if (error.response.status === 405) {
                message.html = 'API Route Method Not Allowed'
            } else if (error.response.status === 422) {
                //Validation Message
            } else if (error.response.status >= 500) {
                message.html = 'Server Error'
            }

            //Try to Use the Response Message
            if (error.hasOwnProperty('response') && error.response.hasOwnProperty('data')) {
                if (error.response.data.hasOwnProperty('message') && error.response.data.message.length > 0) {
                    message.html = error.response.data.message
                }
            }
        }

        return message;
        //Toast the Message
        //if (message.html.length > 0) {
        //Toast.create.negative(message)
        // }
    }
}