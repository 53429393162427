import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [{
    path: '/unassigned/:id?',
    name: 'unassigned',
    component: () => import( /* webpackChunkName: "unassigned" */ '../views/Unassigned.vue')
  }, {
    path: '/myconversation/:id?',
    name: 'myconversation',
    component: () => import( /* webpackChunkName: "myconversation" */ '../views/MyConversation.vue')
  }, {
    path: '/allmessage/:id?',
    name: 'allmessage',
    component: () => import( /* webpackChunkName: "allmessage" */ '../views/AllMessage.vue')
  }, {
    path: '/contact/:id?',
    name: 'contact',
    component: () => import( /* webpackChunkName: "contact" */ '../views/Contact.vue')
  }, {
    path: '/user/:id?',
    name: 'user',
    component: () => import( /* webpackChunkName: "contact" */ '../views/User.vue')
  }, 
  {
    path: '/commchannel/:id?',
    name: 'commchannel',
    component: () => import( /* webpackChunkName: "commchannel" */ '../views/CommChannel.vue')
  },
  {
    path: '/autochatsetting/:id?',
    name: 'chatsetting',
    component: () => import( /* webpackChunkName: "commchannel" */ '../views/AutoChatSetting.vue')
  },
  {
    path: '/categoryautochat/:id?',
    name: 'categoryautochat',
    component: () => import( /* webpackChunkName: "commchannel" */ '../views/CategoryAutoChat.vue')
  },
  {
    path: '/broadcast/:id?',
    name: 'broadcast',
    component: () => import( /* webpackChunkName: "commchannel" */ '../views/Broadcast.vue')
  },
  {
    path: '/broadcast-statistik/:id?',
    name: 'broadcast-statistik',
    component: () => import( /* webpackChunkName: "commchannel" */ '../views/BroadcastStatistik.vue')
  },
  {
    path: '/broadcast-list/:id?',
    name: 'broadcast-list',
    component: () => import( /* webpackChunkName: "commchannel" */ '../views/BroadcastList.vue')
  },
  {
    path: '/setting/:id?',
    name: 'setting',
    component: () => import( /* webpackChunkName: "commchannel" */ '../views/Setting.vue')
  },
  // {
  //   path: '/dashboard/:id?',
  //   name: 'dashboard',
  //   component: () => import( /* webpackChunkName: "commchannel" */ '../views/Dashboard.vue')
  // },
  {
    path: '/about',
    name: 'about',
    component: () => import( /* webpackChunkName: "about" */ '../views/About.vue')
  }, {
    path: '/login',
    alias: '/',
    name: 'login',
    component: () => import( /* webpackChunkName: "login" */ '../views/Login.vue')
  },
  {
    path: '/group/:id?',
    name: 'group',
    component: () => import( /* webpackChunkName: "group" */ '../views/Group.vue')
  },
  {
    path: '/session',
    name: 'session',
    component: () => import( /* webpackChunkName: "session" */ '../views/SessionInfo.vue')
  },
  {
    path: '/report',
    name: 'report',
    component: () => import( /* webpackChunkName: "session" */ '../views/Report.vue')
  },
  {
    path: '/label',
    name: 'label',
    component: () => import( /* webpackChunkName: "session" */ '../views/Label.vue')
  },
  {
    path: '/report-count',
    name: 'report count',
    component: () => import( /* webpackChunkName: "session" */ '../views/CountReport.vue')
  },
  {
    path: '/report-cancel-order',
    name: 'report cancel order',
    component: () => import( /* webpackChunkName: "session" */ '../views/ReportCancelOrder.vue')
  },
  {
    path: '/set-presence',
    name: 'set presence',
    component: () => import( /* webpackChunkName: "session" */ '../views/SetPresence.vue')
  },
  {
    path: '/calendar',
    name: 'calendar',
    component: () => import( /* webpackChunkName: "session" */ '../views/Calendar.vue')
  },
  {
    path: '/dashboard-chat',
    name: 'dashboard chat',
    component: () => import( /* webpackChunkName: "session" */ '../views/DashboardChat.vue')
  },
  {
    path: '/dashboard-order',
    name: 'dashboard order',
    component: () => import( /* webpackChunkName: "session" */ '../views/DashboardOrder.vue')
  },
  {
    path: '/dashboard-complain',
    name: 'dashboard complain',
    component: () => import( /* webpackChunkName: "session" */ '../views/DashboardComplain.vue')
  },
  {
    path: '/dashboard-agent',
    name: 'dashboard agent',
    component: () => import( /* webpackChunkName: "session" */ '../views/DashboardAgent.vue')
  },
  {
    path: '/dashboard-responsetime',
    name: 'dashboard responsetime',
    component: () => import( /* webpackChunkName: "session" */ '../views/DashboardResponseTime.vue')
  },{
    path: '/email/:id?',
    name: 'email',
    component: () => import( /* webpackChunkName: "email" */ '../views/UnassignedEmail.vue')
  },{
    path: '/myemail/:id?',
    name: 'myemail',
    component: () => import( /* webpackChunkName: "myemail" */ '../views/MyEmail.vue')
  },{
    path: '/allemail/:id?',
    name: 'allemail',
    component: () => import( /* webpackChunkName: "allemail" */ '../views/AllEmail.vue')
  },{
    path: '/pendingemail/:id?',
    name: 'pendingemail',
    component: () => import( /* webpackChunkName: "pendingemail" */ '../views/PendingEmail.vue')
  },{
    path: '/comments-fb/:id?',
    name: 'comments-fb',
    component: () => import( /* webpackChunkName: "comments-fb" */ '../views/CommentFB.vue')
  },{
    path: '/comments-ig/:id?',
    name: 'comments-ig',
    component: () => import( /* webpackChunkName: "comments-ig" */ '../views/CommentIG.vue')
  }
]

const router = new VueRouter({
  routes
})

export default router